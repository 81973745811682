import router from '../../../router'
import apolloClient from '../../../apolloClient'
import moment from "moment"

/**
 * The uiStore holds states of the user interface, like selections, that must be remembers as long
 * as the application is running.
 */
const store = {
  namespaced: true,

  state: {
    // german is our default language, but can be overwritten by the local storage
    // and/or change in the app at runtime
    language: "de",

    languages: {
      'de': 'Deutsch',
      'en': 'English',
    },

    languagesMyTrip: {
      'da': 'Dansk',
      'de': 'Deutsch',
      'en': 'English',
      'fr': 'Français',
      'it': 'Italiano',
      'nl': 'Nederlands',
      'pl': 'Polski',
      'pt': 'Português',
      'es': 'Español',   
      'cs': 'Česky',
      'tr': 'Türkçe', 
      'hu': 'Magyar'
    },

    // Holds the currently selected touroperators from the assortments.
    // Used in Destination (Detailansicht) view
    assortmentTouroperators: [],

    // the restriction type (BUSINESS, TOURISM) is stored globally for the app, so all
    // fields should show the same value
    restrictionType: "BUSINESS",

    // when the user viewed the news (in the newsboard/index) last time.
    // All news newer than that will be marked as "new"
    lastNewsVisitDate: null,

    // show the latest DM article on the home page
    showLastDMNews: true,

    // it is necessary to remember what the last news the user saw in order to display a new one if the window is closed
    lastArticleId: null,

    // Default country and nationality for auto-complete search
    defaultCountry: null,
    defaultNationality: null,

    // Search data so we can automatically fill out the form
    autocompleteForm: false,
    selectedCountries: [],
    departureCountry: null,
    transitCountry: null,

    // Saving search parameters to autocomplete the query when the page reloads
    searchParams: null,

    destinationLookupHistory: [],
    originLookupHistory: [],
    countryLookupHistory: [],
    transitLookupHistory: []
  },

  getters: {
    unreadNewsCount(state, getters, rootState, rootGetters) {
      return rootGetters['newsStore/newsWithIsUnread'].filter(entry => entry.isNew).length
    },
    unreadEventsCount(state, getters, rootState, rootGetters) {
      return rootGetters['eventsLatestSignificantUpdatesStore/withIsUnread'].filter(entry => entry.isNew).length
    },
  },

  mutations: {
    SET_LANGUAGE(state, language)  {
      state.language = language

      localStorage.setItem("language", language)
    },

    SET_ASSORTMENTTOUROPERATORS(state, assortmentTouroperators)  {
      state.assortmentTouroperators = assortmentTouroperators
    },

    SET_RESTRICTIONTTYPE(state, restrictionType) {
      state.restrictionType = restrictionType

      localStorage.setItem("restrictionType", restrictionType)
    },

    /**
     * lastNewsVisitDate must be a moment (!) object!
     */
    SET_LASTNEWSVISITDATE(state, lastNewsVisitDate) {
      state.lastNewsVisitDate = lastNewsVisitDate

      localStorage.setItem("lastNewsVisitDate", lastNewsVisitDate.format())
    },

    SET_SHOW_LAST_DM_NEWS(state, showLastDMNews) {
      state.showLastDMNews = showLastDMNews

      if (showLastDMNews) {
        localStorage.setItem("showLastDMNews", "1")
      } else {
        localStorage.setItem("showLastDMNews", "0")
      }
    },

    SET_LAST_SHOW_ARTICLE_ID(state, id) {
      state.lastArticleId = id

      localStorage.setItem("lastArticleId", id)
    },

    SET_DESTINATION_LOOKUP_HISTORY(state, items) {
      state.destinationLookupHistory = items
      localStorage.setItem("destinationLookupHistory", JSON.stringify(state.destinationLookupHistory))
    },

    SET_TRANSIT_LOOKUP_HISTORY(state, items) {
      state.transitLookupHistory = items
      localStorage.setItem("transitLookupHistory", JSON.stringify(state.transitLookupHistory))
    },

    SET_ORIGIN_LOOKUP_HISTORY(state, items) {
      state.originLookupHistory = items
      localStorage.setItem("originLookupHistory", JSON.stringify(state.originLookupHistory))
    },

    ADD_ORIGIN_LOOKUP_HISTORY(state, lookupItem) {
      if (lookupItem !== null){
        lookupItem.lookupDate = moment(new Date(), moment.ISO_8601).format()
        state.originLookupHistory.unshift(lookupItem)

        if (state.originLookupHistory.length > 50) {
          state.originLookupHistory = state.originLookupHistory.slice(0, 50)
        }

        localStorage.setItem("originLookupHistory", JSON.stringify(state.originLookupHistory))
      }
    },

    ADD_TRANSIT_LOOKUP_HISTORY(state, lookupItem) {
      if (lookupItem !== null){
        lookupItem.lookupDate = moment(new Date(), moment.ISO_8601).format()
        state.transitLookupHistory.unshift(lookupItem)

        if (state.transitLookupHistory.length > 50) {
          state.transitLookupHistory = state.transitLookupHistory.slice(0, 50)
        }

        localStorage.setItem("transitLookupHistory", JSON.stringify(state.transitLookupHistory))
      }
    },

    ADD_DESTINATION_LOOKUP_HISTORY(state, lookupItem) {
      if (lookupItem !== null){
        lookupItem.lookupDate = moment(new Date(), moment.ISO_8601).format()
        state.destinationLookupHistory.unshift(lookupItem)

        if (state.destinationLookupHistory.length > 50) {
          state.destinationLookupHistory = state.destinationLookupHistory.slice(0, 50)
        }

        localStorage.setItem("destinationLookupHistory", JSON.stringify(state.destinationLookupHistory))
      }
    },

    SET_COUNTRY_LOOKUP_HISTORY(state, items) {
      state.countryLookupHistory = items
      localStorage.setItem("countryLookupHistory", JSON.stringify(state.countryLookupHistory))
    },

    ADD_COUNTRY_LOOKUP_HISTORY(state, lookupItem) {
      if (lookupItem !== null){
        lookupItem.lookupDate = moment(new Date(), moment.ISO_8601).format()
        state.countryLookupHistory.unshift(lookupItem)

        if (state.countryLookupHistory.length > 50) {
          state.countryLookupHistory = state.countryLookupHistory.slice(0, 50)
        }

        localStorage.setItem("countryLookupHistory", JSON.stringify(state.countryLookupHistory))
      }
    },

    SET_DEFAULT_COUNTRY(state, defaultCountryIso2) {
      state.defaultCountry = defaultCountryIso2

      localStorage.setItem("defaultCountry", defaultCountryIso2)
    },

    SET_DEFAULT_NATIONALITY(state, defaultCountryIso2) {
      state.defaultNationality = defaultCountryIso2

      localStorage.setItem("defaultNationality", defaultCountryIso2)
    },

    SET_AUTOCOMPLETE_FORM(state, autocompleteForm) {
      state.autocompleteForm = autocompleteForm

      if (autocompleteForm) {
        localStorage.setItem("autocompleteForm", "1")
      } else {
        localStorage.setItem("autocompleteForm", "0")
      }
    },

    UPDATE_FORM_DATA(state, payload){

      state.selectedCountries = payload.selectedCountries
      state.departureCountry = payload.departureCountry
      state.transitCountry = payload.transitCountry

      localStorage.setItem("selectedCountries", JSON.stringify(state.selectedCountries))
      localStorage.setItem("departureCountry", JSON.stringify(state.departureCountry))
      localStorage.setItem("transitCountry", JSON.stringify(state.transitCountry))
    },

    SET_FORM_DATA(state){
      let selectedCountries = JSON.parse(localStorage.getItem("selectedCountries"))
      let departureCountry = JSON.parse(localStorage.getItem("departureCountry"))
      let transitCountry = JSON.parse(localStorage.getItem("transitCountry"))

      state.selectedCountries = selectedCountries
      state.departureCountry = departureCountry
      state.transitCountry = transitCountry
    },

    SET_SEARCH_PARAMS(state, params){
      state.searchParams = params

      localStorage.setItem("searchParams", JSON.stringify(state.searchParams))
    }
  },

  actions: {
    initStore({ commit, dispatch }) {
      // set language if defined in the local storage
      // get default restrictionType
      if (localStorage.getItem("language")) {
        commit("SET_LANGUAGE", localStorage.getItem("language"))
      }

      // get default restrictionType
      if (localStorage.getItem("restrictionType")) {
        commit("SET_RESTRICTIONTTYPE", localStorage.getItem("restrictionType"))
      }

      // Get destinationLookupHistory as json
      if (localStorage.getItem("destinationLookupHistory")) {
        let items = JSON.parse(localStorage.getItem("destinationLookupHistory"))
        commit("SET_DESTINATION_LOOKUP_HISTORY", items)
      }

      // Get transitLookupHistory as json
      if (localStorage.getItem("transitLookupHistory")) {
        let items = JSON.parse(localStorage.getItem("transitLookupHistory"))
        commit("SET_TRANSIT_LOOKUP_HISTORY", items)
      }

      // Get originLookupHistory as json
      if (localStorage.getItem("originLookupHistory")) {
        let items = JSON.parse(localStorage.getItem("originLookupHistory"))
        commit("SET_ORIGIN_LOOKUP_HISTORY", items)
      }

      // Get countryLookupHistory as json
      if (localStorage.getItem("countryLookupHistory")) {
        let items = JSON.parse(localStorage.getItem("countryLookupHistory"))
        commit("SET_COUNTRY_LOOKUP_HISTORY", items)
      }

      // Get information about whether to display the latest news about the DM
      if (localStorage.getItem("showLastDMNews")) {
        if (localStorage.getItem("showLastDMNews") == "1") {
          commit("SET_SHOW_LAST_DM_NEWS", true)
        } else {
          commit("SET_SHOW_LAST_DM_NEWS", false)
        }
      }

      // Get the id of the latest news about the DM
      if (localStorage.getItem("lastArticleId")) {
        commit("SET_LAST_SHOW_ARTICLE_ID", localStorage.getItem("lastArticleId"))
      } else {
        commit("SET_LAST_SHOW_ARTICLE_ID", null)
      }

      // init lastNewsVisitDate fromt the localStore. We will update it alongside
      // the store from here on
      let lastIndexVisited = localStorage.getItem("lastNewsVisitDate")
      if (lastIndexVisited != null) {
        commit("SET_LASTNEWSVISITDATE", moment(lastIndexVisited))
      } else {
        // default: we set a very old default date for the components
        commit("SET_LASTNEWSVISITDATE", moment().subtract({years: 20}))
      }

      // Getting the default country from the local storage
      if (localStorage.getItem("defaultCountry")) {
        commit("SET_DEFAULT_COUNTRY", localStorage.getItem("defaultCountry"))
      }

      // Getting the default nationality from the local storage
      if (localStorage.getItem("defaultNationality")) {
        commit("SET_DEFAULT_NATIONALITY", localStorage.getItem("defaultNationality"))
      }

      // Retrieving saved form data
      if (localStorage.getItem("selectedCountries") && localStorage.getItem("departureCountry") &&
      localStorage.getItem("transitCountry")) {
        commit("SET_FORM_DATA")
      }

      commit("SET_AUTOCOMPLETE_FORM", false)

      // Getting search parameters from localStorage
      if (localStorage.getItem("searchParams")) {
         commit('SET_SEARCH_PARAMS', JSON.parse(localStorage.getItem("searchParams")))
      }

      // to get the most up-to-date newscount, we pull in new news and last changed every 5 minutes
      setInterval(() => {
        // auto reload the news only when the user is logged in
        if (localStorage.getItem("authToken")) {
          dispatch("fetchNewsAndLatestUpdates")
        }
      }, 300000);
    },

    /**
     * Required to update the newsboard new-news counter
     * Placed here, as it includes calls for multiple other stores
     */
    fetchNewsAndLatestUpdates({ commit, dispatch }) {
      // will return the promise when all requests finished successful, or the first failure
      return Promise.all([
        dispatch("newsStore/fetchAll", {force: true}, {root: true}),
        dispatch("tickerStore/fetchAll", { variables: { limit: 100, countryFilter: false }, force: true}, {root: true}),
        //dispatch("entryRestrictionLatestSignificantUpdatesStore/fetchAll", {force: true}, {root: true}),
        dispatch("eventsLatestSignificantUpdatesStore/fetchAll", { variables: { limit: 50, countryFilter: false }, force: true}, {root: true}),
        dispatch("eventsLatestSignificantUpdatesPinnedStore/fetchAll", { variables: { countryFilter: false }, force: true}, {root: true}),
        dispatch("organisationCountrySelectionsStore/fetchAll", {force: true}, {root: true})
      ])
    },

    setLastNewsVisitDateToNow({ commit, dispatch }) {
      commit("SET_LASTNEWSVISITDATE", moment())
    }
  }
}

export default store