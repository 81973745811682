import gql from 'graphql-tag'

export const NEWS_QUERY = gql`
query News{
  news {
    results(limit: 200) {
      id
      text
      textDe
      textEn
      title
      titleDe
      titleEn
      importantUntil
      newsType
      tags {
        name
        order
      }
      country {
        id
        nameShort
        iso2
      }
      image
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      modifiedAt
      modifiedBy {
        id
        firstName
        lastName
      }
      significantUpdateAt
    }
  }
}
`

export const NEWS_CREATE_MUTATION = gql`
mutation CreateNews($input: NewsCreateGenericType!) {
  createNews(input: $input) {
    news {
      id
    }
    ok
    errors {
      field
      messages
    }
  }
}
`

export const NEWS_UPDATE_MUTATION = gql`
mutation UpdateNews($input: NewsUpdateGenericType!) {
  updateNews(input: $input) {
    news {
      id
    }
    ok
    errors {
      field
      messages
    }
  }
}
`


export const NEWS_DELETE_MUTATION = gql`
mutation DeleteNews($id: ID!) {
  deleteNews(id: $id) {
    news {
      id
    }
    ok
    errors {
      field
      messages
    }
  }
}
`
