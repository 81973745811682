import gql from 'graphql-tag'
import {COUNTRY_FRAGMENT} from "../store/graphql";

export const MAP_WORLD_COUNTRY_STATUS = gql`
query MapWorldCountryStatus($restrictionType: String!, $dateStart: Date!, $dateEnd: Date!){
  mapWorldCountryStatus(restrictionType: $restrictionType, dateStart: $dateStart, dateEnd: $dateEnd){
    countries {
      countryId
      iso2
      nameShort
      status
      regionDeviation
      rkiStatus
      sevenDayIncidenceCurrent
      sevenDayIncidencePrev
    }
  }
}
`

export const MAP_COUNTRY_REGION_STATUS = gql`
query MapCountryRegionStatus($iso2: String!, $restrictionType: String!, $dateStart: Date!, $dateEnd: Date!){
  mapCountryRegionStatus(iso2: $iso2, restrictionType: $restrictionType, dateStart: $dateStart, dateEnd: $dateEnd){
    regions  {
      regionId
      name
      status
      geojson
    }
  }
}
`

export const MAP_COUNTRY_STATUS = gql`
query MapCountryStatus($id: ID!, $restrictionType: String!, $dateStart: Date!, $dateEnd: Date!){
  mapCountryStatus(id: $id, restrictionType: $restrictionType, dateStart: $dateStart, dateEnd: $dateEnd){
    status
    statusForeignOffice
    statusDestinationCountry
    rkiStatus
   }
}
`

export const MAP_REGION_STATUS = gql`
query MapRegionStatus($id: ID!, $restrictionType: String!, $dateStart: Date!, $dateEnd: Date!){
  mapRegionStatus(id: $id, restrictionType: $restrictionType, dateStart: $dateStart, dateEnd: $dateEnd){
    name
    status
    statusForeignOffice
    statusDestinationCountry
  }
}
`

export const MAP_CIDMER_CONTENT_COUNTRY_QUERY = gql`
query MapCidmerDestinationContent($nationalityIso2: String!) {
  mapCidmerDestinationContent(nationalityIso2: $nationalityIso2) {
    results {
      destination {
        ...CountryFragment
      }
      items
    }
  }
}
${COUNTRY_FRAGMENT}
`