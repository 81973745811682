import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { EVENTS_QUERY } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: EVENTS_QUERY,
    fetchAllFieldName: 'mapEvents',
  },
})
/* eslint-enable no-unused-vars */

export default store

