import gql from 'graphql-tag'

export const MYJOURNEY_CREATE_MUTATION = gql`
mutation CreateMyjourney($input: CreateMyJourneyInputType!) {
  createMyjourney(input: $input) {
    myjourney {
      url
    }
    ok
  }
}
`

export const MYJOURNEY_LIST_QUERY = gql`
query MyJourneys($limit: Int, $offset: Int, $search: String) {
  myjourneys(search: $search) {
    results(limit: $limit, offset: $offset, ordering: "-created_at") {
      id
      departureName
      transitName
      restrictionType
      dateStart
      dateEnd
      email
      isActive
      isManuallyDisabled
      language
      internalNote
      sendUpdates
      showEvents
      createdAt
      link

      destinations {
        destinationName
      }

      citizenships {
        citizenshipCountry {
          iso2
          nameShort
        }
      }
    }
    totalCount
  }
}
`

export const UPDATE_MY_JOURNEY_NOTE_EMAIL_MUTATION = gql`
mutation updateMyJourneyNoteEmail($input: UpdateMyJourneyNoteEmailInputType!) {
  updateMyjourneyEmailNote(input: $input) {
    ok
    error
    id
  }
}
`