<template>
  <v-navigation-drawer color="#032545" app permanent width="300px" v-if="authToken" class="app-bar">

    <v-list>
      <v-list-item class="py-4 justify-center">
        <router-link :to="{name: 'index'}" class="site-name"><img src="@/assets/logo_dm_sidebar.png" class="header-logo" alt="dm logo"/></router-link>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list class="py-0 app-bar-list">
      <v-list-item class="app-bar-item" @click="homePage" :class="{ 'v-list-item--active': $route.name === 'index'}">
        <v-list-item-icon class="app-bar-item-icon">
          <img height="24" src="@/assets/appbar_icons/start.png" alt="home icon"/>
        </v-list-item-icon>
        <span class="app-bar-item-text">{{ $t('main.HOME') }}</span>
      </v-list-item>

      <v-divider class="divider"></v-divider>

      <v-list-item class="app-bar-item" :to="{name: 'destination-map'}" v-if="$hasPermission('a3m_core.DestinationManager')">
        <v-list-item-icon class="app-bar-item-icon">
          <img height="24" src="@/assets/appbar_icons/map.svg" alt="map icon"/>
        </v-list-item-icon>
        <span class="app-bar-item-text">{{ $t('main.RESTRICTION_MAP') }}</span>
      </v-list-item>

      <v-divider class="divider" :class="{'divider-disable': !$hasPermission('a3m_core.DestinationManager')}"></v-divider>

      <div class="events-button" v-if="$hasPermission('a3m_core.DestinationManagerEvents')">
        <v-list-item class="app-bar-item" :to="{name: 'events'}">
          <v-list-item-icon class="app-bar-item-icon">
            <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconEvent"></Svg-icon>
          </v-list-item-icon>
          <span class="app-bar-item-text">{{ $t('main.GMEVENTS') }}</span>
        </v-list-item>
        <v-list-item class="app-bar-item" :to="{name: 'events-map'}">
          <v-list-item-icon class="app-bar-item-icon">
            <img height="24" src="@/assets/appbar_icons/event-map.svg" alt="event map icon" />
          </v-list-item-icon>
          <span class="app-bar-item-text">{{ $t('main.EVENT_MAP') }}</span>
        </v-list-item>

        <v-divider class="divider"></v-divider>
      </div>

      <v-list-item class="app-bar-item" :to="{name: 'mytrip-history'}" v-if="$hasPermission('a3m_core.DestinationManagerListMyJourney')">
        <v-list-item-icon class="app-bar-item-icon">
          <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconMyTrip"></Svg-icon>
        </v-list-item-icon>
        <span class="app-bar-item-text">{{ $t('main.MYJOURNEY_PAGES') }}</span>
      </v-list-item>

      <v-divider class="divider" :class="{'divider-disable': !$hasPermission('a3m_core.DestinationManagerListMyJourney')}"></v-divider>

      <v-list-item class="app-bar-item" :to="{name: 'countries-information'}" v-if="$hasPermission('a3m_core.DestinationManagerCDB')">
        <v-list-item-icon class="app-bar-item-icon">
          <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconCdb"></Svg-icon>
        </v-list-item-icon>
        <span class="app-bar-item-text">{{ $t('main.COUNTRYDATABASE') }}</span>
      </v-list-item>

      <v-divider class="divider" :class="{'divider-disable': !$hasPermission('a3m_core.DestinationManagerCDB')}"></v-divider>

      <v-list-item class="app-bar-item" :to="{name: 'news'}">
        <v-list-item-icon class="app-bar-item-icon">
          <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconNews"></Svg-icon>
        </v-list-item-icon>
        <span class="app-bar-item-text">{{ $t('main.NEWS') }}</span>
      </v-list-item>

      <v-spacer></v-spacer>

      <v-list-item class="app-bar-item" :href="helpLinkUrl" target="_blank">
        <v-list-item-icon class="app-bar-item-icon">
          <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconHelp"></Svg-icon>
        </v-list-item-icon>
        <span class="app-bar-item-text">{{ $t('main.TUTORIALS_AND_HELP') }}</span>
      </v-list-item>

      <v-list-item
        class="app-bar-item"
        @click="toggleCard"
        :class="{'active-settings': isActive(['profile-edit', 'organisation-countries-selection', 'mytrip-filter-settings'])}">
        <v-list-item-icon class="app-bar-item-icon">
          <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconSettings"></Svg-icon>
        </v-list-item-icon>
        <span class="app-bar-item-text">{{ $t('main.SETTINGS') }}</span>
        <ToolbarAvatar :menu.sync="isCardVisible"/>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
import ToolbarAvatar from '@/components/app/ToolbarAvatar'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiRhombus, mdiCog, mdiNewspaperVariantMultiple, mdiFlagVariant, mdiWalletTravel, mdiHelpCircle } from '@mdi/js';


export default {
  name: 'AppBar',
  components: {
    SvgIcon,
    ToolbarAvatar
  },
  data(){
    return {
      iconEvent: mdiRhombus,
      iconSettings: mdiCog,
      iconNews: mdiNewspaperVariantMultiple,
      iconCdb: mdiFlagVariant,
      iconMyTrip: mdiWalletTravel,
      iconHelp: mdiHelpCircle,
      isCardVisible: false, 
    }
  },
  computed: {
    ...mapState({
      authToken: state => state.authStore.authToken,
      user: state => state.authStore.user,
      loading: state => state.loading,
      queriesInProgress: state => state.queriesInProgress,
      language: state => state.uiStore.language,
    }),
    ...mapGetters({
      cdbUrl: 'authStore/cdbUrl'
    }),
    helpLinkUrl() {
      if (this.language == 'de') {
        return "https://www.global-monitoring.com/blog/portfolio/faq-destination-manager/"
      }

      return "https://www.global-monitoring.com/en/blog/portfolio/faq-destination-manager/"
    },
  },
  methods: {
    ...mapMutations({
      SET_AUTOCOMPLETE_FORM: 'uiStore/SET_AUTOCOMPLETE_FORM',
    }),
    toggleCard() {
      this.isCardVisible = !this.isCardVisible;
    },
    isActive(routeNames) {
      return routeNames.includes(this.$route.name);
    },
    homePage() {
      this.SET_AUTOCOMPLETE_FORM(false)
      this.$router.push({ name: 'index' })
    }
  }
}
</script>

<style>
  .app-bar .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
  }
</style>

<style scoped>
  .app-bar {
    z-index: 1000;
    display: flex;
  }

  .app-bar > .v-navigation-drawer__content {
    display: flex;
  }

  .header-logo {
    height: 90px;
  }

  .app-bar-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .app-bar-item-text {
    color: #e1e1e1;
    font-weight: 600;
    font-size: 14px;
  }

  .app-bar-item {
    flex: 0;
    padding-block: 4px;
    padding-inline: 5%;
    background-color: #032545;
    height: 48px;
  }

  .app-bar-item:hover {
    background-color: #002f59;
  }

  .app-bar-item .v-list-item__icon {
    margin: 12px 16px;
  }

  .divider {
    background-color: #e1e1e130;
    margin-inline: 60px;
    border-width: 0.5px 0 0;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  

  .menu-icon {
    color: #e1e1e1;
  }

  .v-list-item--active, .v-list-item--active:hover {
    background-color: #286dd4;
  }

  .active-settings {
    background-color: #286dd4;
  }

  .app-bar-item-icon {
    align-items: center;
    height: 100%;
    margin-block: 0 !important;
    margin-inline: 12px !important;
  }

  .divider-disable {
    display: none;
  }
</style>