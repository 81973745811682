import gql from 'graphql-tag'


export const EVENTS_QUERY = gql`
query mapEvents {
  mapEvents {
    results {
      id
      event{
        category {
          name
          code
        }
        country {
          id
        }
        title
        level
        staticPageUrl
        gmUniqueEventId
        latitude
        longitude
      }
      travelersNow
      travelersPotentiallyAffected
      live
    }
  }
}
`;