import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { NEWS_TAGS_QUERY} from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: NEWS_TAGS_QUERY,
    fetchAllFieldName: 'newsTags',

  },
})
/* eslint-enable no-unused-vars */

export default store

