import gql from 'graphql-tag'

const EVENTS_FIELDS = gql`
  fragment EventFieldsFragment on EventGenericType {
    category {
      name
      code
    }
    country {
      id
      iso2
      nameShort
    }
    title
    isActive
    level
    significantUpdateAt
    staticPageUrl
    timeEnd
    timeStart
    countUpdates
    gmUniqueEventId
  }
`;

export const EVENT_WRAPPER_FIELDS = gql`
  fragment BookingEventFieldsFragment on BookingEvent {
    id
    event {
      ...EventFieldsFragment
    }
    travelersNow
    travelersPotentiallyAffected
    live
  }
  ${EVENTS_FIELDS}
`;

export const EVENTS_QUERY = gql`
query EventsLatestSignificantUpdates($limit: Int, $countryFilter: Boolean) {
  eventsLatestSignificantUpdates(limit: $limit, countryFilter: $countryFilter) {
    results {
      events {
        ...BookingEventFieldsFragment
      }
      liveEvents {
        ...BookingEventFieldsFragment
      }
    }
  }
}
${EVENT_WRAPPER_FIELDS}
`;