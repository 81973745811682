import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { MAP_COUNTRIES_QUERY } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: MAP_COUNTRIES_QUERY,
    fetchAllFieldName: 'mapCountries',
  },
})
/* eslint-enable no-unused-vars */

export default store

