
// If we found webpackHotUpdate, which is only available on the local Vue dev server,
// we use our local developmet url
let isDevelopmentSystem = false
if (typeof webpackHotUpdate !== 'undefined') {
  isDevelopmentSystem = true
}

// Local development requires to use a different port to reach the django development server
let backendBaseUri = ''
if (isDevelopmentSystem) {
  backendBaseUri = 'http://'+window.location.hostname+':9901'
}

let backendApiUrl = backendBaseUri + '/api'
let backendDownloadsUrl = backendBaseUri + '/downloads'
let backendMediaUrl = backendBaseUri + '/media'
let backendMyJourneyUrl = backendBaseUri + '/m'
let backendAuthUrl = backendBaseUri + '/auth'

export {
  isDevelopmentSystem,
  backendBaseUri,
  backendApiUrl,
  backendDownloadsUrl,
  backendMyJourneyUrl,
  backendMediaUrl,
  backendAuthUrl
}