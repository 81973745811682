import moment from "moment"
import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { NEWS_QUERY, NEWS_CREATE_MUTATION, NEWS_UPDATE_MUTATION, NEWS_DELETE_MUTATION} from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: NEWS_QUERY,
    fetchAllFieldName: 'news',

    createMutation: NEWS_CREATE_MUTATION,
    createMutationName: 'createNews',
    createFieldName: 'news',

    updateMutation: NEWS_UPDATE_MUTATION,
    updateMutationName: 'updateNews',
    updateFieldName: 'news',

    deleteMutation: NEWS_DELETE_MUTATION,
    deleteMutationName: 'deleteNews',
    deleteFieldName: 'news',
  },
  actions: {
    checkShowLastDMNews({ commit, getters, rootState }) {
      let news = getters.newsWithIsUnread.filter(entry => entry.newsType == 'DESTINATIONMANAGER')
          .sort((a, b) => moment(a.significantUpdateAt).diff(moment(b.significantUpdateAt)));
      let lastNewsId = news.length > 0 ? String(news.slice(-1)[0].id) : null;

      if (lastNewsId !== rootState.uiStore.lastArticleId) {
        commit('uiStore/SET_SHOW_LAST_DM_NEWS', true, { root: true });
      }

      return lastNewsId
    }
  },
  getters: {
    newsWithIsUnread(state, getters, rootState) {
      let news = []
      let lastVisitDate = localStorage.getItem('lastNewsVisitDate')
      for (let entry of state.entries) {
        entry.isUnread = false
        entry.isNew = false
        if (moment(entry.createdAt) > moment(lastVisitDate)) {
          entry.isUnread = true
          entry.isNew = true
        }
        news.push(entry)
      }
      return news
    },

    importantNews(state, getters, rootState) {
      return getters.newsWithIsUnread.filter(entry => entry.newsType == 'GENERIC')
    },

    destinationManagerNews(state, getters, rootState) {
      return getters.newsWithIsUnread.filter(entry => entry.newsType == 'DESTINATIONMANAGER')
    }
  }
})
/* eslint-enable no-unused-vars */

export default store

