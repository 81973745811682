import { BaseRepository } from './baseRepository'
import { MAP_COUNTRY_STATUS, MAP_REGION_STATUS, MAP_WORLD_COUNTRY_STATUS, MAP_COUNTRY_REGION_STATUS, MAP_CIDMER_CONTENT_COUNTRY_QUERY } from "./mapRepository.graphql";

export class MapRepository extends BaseRepository {

  async getWorldCountryStatus(restrictionType, dateStart, dateEnd) {
    return await this.query(
      MAP_WORLD_COUNTRY_STATUS, {
        restrictionType: restrictionType,
        dateStart: dateStart,
        dateEnd: dateEnd
      },
      'mapWorldCountryStatus'
    )
  }

  async getCountryRegionStatus(iso2, restrictionType, dateStart, dateEnd) {
    return await this.query(
      MAP_COUNTRY_REGION_STATUS, {
        iso2,
        restrictionType,
        dateStart,
        dateEnd
      },
      'mapCountryRegionStatus'
    )
  }

  async getCountryStatus(countryId, restrictionType, dateStart, dateEnd) {
    return await this.query(
      MAP_COUNTRY_STATUS, {
        id: countryId,
        restrictionType,
        dateStart,
        dateEnd
      },
      'mapCountryStatus'
    )
  }


  async getRegionStatus(regionId, restrictionType, dateStart, dateEnd) {
    return await this.query(
      MAP_REGION_STATUS, {
        id: regionId,
        restrictionType,
        dateStart,
        dateEnd
      },
      'mapRegionStatus'
    )
  }


  async getMapCidmerDestinationContent(nationalityIso2) {
    return await this.query(
      MAP_CIDMER_CONTENT_COUNTRY_QUERY, {
          nationalityIso2
      },
      'mapCidmerDestinationContent'
    )
  }

}