import gql from 'graphql-tag'

import {EVENT_WRAPPER_FIELDS} from "../eventsLatestSignificantUpdatesStore/graphql";


export const EVENTS_QUERY = gql`
query Events {
  allEvents {
    results {
      events {
        ...BookingEventFieldsFragment
      }
      liveEvents {
        ...BookingEventFieldsFragment
      }
      pinnedEvents {
        ...BookingEventFieldsFragment
      }
    }
  }
}
${EVENT_WRAPPER_FIELDS}
`;