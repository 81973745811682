import moment from "moment"
import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { EVENTS_QUERY, PIN_EVENT_MUTATION, UNPIN_EVENT_MUTATION } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: EVENTS_QUERY,
    fetchAllFieldName: 'eventsLatestSignificantUpdatesPinned',
  },
  getters: {
    withIsUnread(state, getters, rootState) {
      let news = []
      for (let entry of state.entries) {
        entry.isUnread = false
        entry.isNew = entry.event.countUpdates === 0
        if (moment(entry.significantUpdateAt) > rootState.uiStore.lastNewsVisitDate) {
          entry.isUnread = true
        }
        news.push(entry)
      }

      return news
    }
  },
  actions: {
    async pin({ state, commit, rootState, dispatch }, eventId) {
      try {
        let result = await dispatch('query', {
            query: PIN_EVENT_MUTATION,
            variables: {
              input: {
                event: eventId
              }
            }
          },
          { root: true }
        )

        return result.data.pinEvent.ok
      } catch(error) {
        return false
      }
    },
    async unpin({ state, commit, rootState, dispatch }, eventId) {
      try {
        let result = await dispatch('query', {
            query: UNPIN_EVENT_MUTATION,
            variables: {
              input: {
                event: eventId
              }
            }
          },
          { root: true }
        )

        return result.data.pinEvent.ok
      } catch(error) {
        return false
      }
    }
  }
})
/* eslint-enable no-unused-vars */

export default store

