import gql from 'graphql-tag'

import { EVENT_WRAPPER_FIELDS } from "../store/modules/eventsLatestSignificantUpdatesStore/graphql";

export const LOOKUP_DESTINATIONS = gql`
query LookupDestinations($search: String!){
  lookupDestinations(search: $search){
  destinations {
      destinationType
      id
      name
    }
  }
}
`

export const LOOKUP_COUNTRIES = gql`
query LookupCountries($search: String!){
  lookupCountries(search: $search) {
    countries {
      destinationType
      id
      name
    }
  }
}
`

export const DESTINATION_DETAIL = gql`
query DestinationDetail(
    $departureType: String!,
    $departureId: ID!,  
    $destinationsTypes: [String]!,
    $destinationIds: [ID]!,
    $citizenshipIds: [ID]!,
    $restrictionType: String!,
    $dateStart: Date!,
    $dateEnd: Date!,

    $transitType: String,
    $transitId: ID,
  ) {
  destinationDetail(
      departureType: $departureType,
      departureId: $departureId,
      destinationsTypes: $destinationsTypes,
      destinationIds: $destinationIds,
      restrictionType: $restrictionType,
      citizenshipIds: $citizenshipIds,
      dateStart: $dateStart,
      dateEnd: $dateEnd,
      transitType: $transitType,
      transitId: $transitId,      
  ){
    departureType
    departureId
    destinationsTypes
    destinationIds
    destinationsIso2
    transitType
    transitId
    citizenshipsCountry
    citizenshipCountryIds
    destinationsNames
    departureName
    transitName
    dateStart
    dateEnd
    restrictionType,   
    events {
        ...BookingEventFieldsFragment
    }
    liveEvents {
        ...BookingEventFieldsFragment
    }
    url
  }
}
${EVENT_WRAPPER_FIELDS}
`

export const SENDENTRYRESTRICTIONFEEDBACK_MUTATION = gql`
mutation SendEntryrestrictionFeedback($input: SendEntryRestrictionFeedbackInputType!) {
  sendEntryrestrictionFeedback(input: $input){
    ok
  }
}
`