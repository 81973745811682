import gql from 'graphql-tag'


export const EVENTS_CATEGORIES_QUERY = gql`
query EventCategories {
  eventcategories {
    results {
       code
       name
    }
  }
}
`;