import gql from 'graphql-tag'


export const NEWS_TAGS_QUERY = gql`
query NewsTags {
  newsTags {
    results {
        name
        nameDe
        nameEn
        order
    }
  }
}
`
